import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Title } from './Description';
import { useInView } from 'react-intersection-observer';

const Skills = () => {
    const [Skills, changeSkills] = useState([
        {
            id: 1,
            name: 'HTML',
            percentage: 90
        },
        {
            id: 2,
            name: 'CSS',
            percentage: 90
        },
        {
            id: 3,
            name: 'Javascript',
            percentage: 80
        },
        {
            id: 4,
            name: 'Reactjs',
            percentage: 70
        },
        {
            id: 5,
            name: 'PHP & Mysql',
            percentage: 55
        },
        {
            id: 6,
            name: 'Wordpress',
            percentage: 85
        }
    ]);

    const { ref, inView } = useInView({
        triggerOnce: true, // La animación solo se activa una vez
        threshold: 0.5 // Se activa cuando al menos el 50% de la sección está en la vista
    });

    return (
        <SkillsContainer ref={ref}>
            <Title>Habilidades</Title>
            {
                Skills.map((skill) => {
                    return (
                        <SkillContainer key={skill.id}>
                            <p>{skill.name}</p>
                            <BarContainer>
                                <Bar percentage={skill.percentage} inView={inView}>{skill.percentage}%</Bar>
                            </BarContainer>
                        </SkillContainer>
                    )
                })
            }
        </SkillsContainer>
    );
}

const SkillsContainer = styled.div`
    width: 100%;

    @media(max-width: 768px){
        padding: 30px 0;
    }
`;

const SkillContainer = styled.div`
    margin-bottom: 20px;

    p{
        font-size: 14px;
        margin-bottom: 4px;
        color: #fff;
    }
`;

const BarContainer = styled.div`
    width: 100%;
    background: #ccc;
    border-radius: 25px;
`;

const fillAnimation = (percentage) => keyframes`
    0% { width: 0; }
    100% { width: ${percentage}%; }
`;

const Bar = styled.div`
    text-align: right;
    padding: 5px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    background: #00b080;
    border-radius: 25px;
    animation: ${({ percentage, inView }) => (inView ? fillAnimation(percentage) : 'none')} 2s forwards;
`;

export default Skills;