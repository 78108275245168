import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Title } from './Description';
import { Paragraph } from './Description';
import { db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Project = () => {
    const { projectId } = useParams(); // Obtiene el ID del proyecto de los parámetros de la URL
    const [project, changeProject] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const projectDoc = doc(db, 'proyectos', projectId); // Referencia al documento del proyecto en Firestore
                const projectSnapshot = await getDoc(projectDoc); // Obtener el documento del proyecto
                if (projectSnapshot.exists()) {
                    changeProject({ ...projectSnapshot.data(), id: projectSnapshot.id });
                } else {
                    console.log("El proyecto no existe");
                }
            } catch (error) {
                console.error("Error al obtener el proyecto:", error);
            }
            setLoading(false);
        };

        fetchProject();

    }, [projectId]);

    if (loading) {
        return <LoadingMessage>Cargando...</LoadingMessage>;
    }

    if (!project) {
        return <ErrorMessage>Ocurrió un error al cargar el proyecto.</ErrorMessage>;
    }

    return (
        <>
            <Helmet>
                <title>{project.nombre}</title>
            </Helmet>
            <ProjectContainer>
                <Title>{project.nombre}</Title>
                <Paragraph>{project.descripcion}</Paragraph>
                <ImagesContainer>
                    {
                        project.imagenes.slice(0, -1).map((imagen, index) => (
                            <img key={index} src={imagen} alt={`Imagen ${index + 1}`} /> //El método slice en JavaScript se utiliza para extraer una sección de un arreglo y devuelve una nueva matriz. Toma dos argumentos opcionales: el índice de inicio y el índice de fin.
                        ))
                    }
                </ImagesContainer>
            </ProjectContainer>
        </>
    );
}

const ProjectContainer = styled.section`

    h3{
        color: #fff;
        font-weight: bold;
        margin-bottom: 12px;
    }

    ul{
        color: #fff;
    }

    ul li{
        margin-bottom: 6px;
    }

    ul li b{
        color: #00b080;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    
    img{
        margin: 20px;
        width: 100%;
        max-width: 720px;
        max-height: 300px;
    }
`;

const LoadingMessage = styled.p`
    color: green;
`;

const ErrorMessage = styled.p`
    color: red;
`;

export default Project;