import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {

    return (
        <ContactContainer id='contact'>
            <NetworksContainer>
                <OtherTitle>Contáctame</OtherTitle>
                <div>
                    <FontAwesomeIcon icon={faWhatsapp} />
                    <span>Whatsapp</span>
                    <p>+58 04126684648</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>Correo</span>
                    <p>emeralvarado1983@gmail.com</p>
                </div>
            </NetworksContainer>
            <MapContainer>
                <OtherTitle>Ubicación</OtherTitle>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63158.908157074635!2d-62.63603200000001!3d8.359116800000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sve!4v1713636566547!5m2!1ses!2sve" width="100%" height="450" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </MapContainer>
            <Copyright>
                Desarrollado por Emerson Alvarado.
            </Copyright>
        </ContactContainer>
    );
}

const ContactContainer = styled.footer`
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const OtherTitle = styled.h2`
    width: 100%;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const NetworksContainer = styled.div`
    width: 45%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    div{
        color: #fff;
        font-size: 32px;
        margin-bottom: 30px;
    }

    svg{
        color: #00b080;
    }

    span{
        font-size: 26px;
        margin-left: 5px;
        color: #00b080;
    }

    p{
        font-size: 20px;
    }

    @media(max-width: 768px){
        width: 100%;
        max-width: 100%;
        padding: 30px 0;
    }
`;

const MapContainer = styled.div`
    width: 45%;
    max-width: 540px;

    iframe{
        border-radius: 5px;
    }

    @media(max-width: 768px){
        width: 100%;
        max-width: 100%;
    }
`;

const Copyright = styled.p`
    width: 100%;
    text-align: center;
    margin-top: 60px;
    padding-top: 60px;
    color: #fff;
    border-top: 1px solid #ccc;
`;

export default Footer;