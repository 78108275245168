import React from 'react';
import styled from 'styled-components';
import Skills from './Skills';

const Description = () => {
    return (
        <DescriptionContainer>
            <Title>Sobre mí</Title>
            <Paragraph>Cuento con cinco años de experiencia trabajando en el área de Frontend. Las principales tecnologías que utilizo son HTML, CSS y JavaScript. Además, hago uso de la biblioteca React para el desarrollo de proyectos, ya que es una de las herramientas más óptimas en la actualidad.</Paragraph>
            <Paragraph>También cabe mencionar que trabajo con el sistema de WordPress, con el cual desarrollo diferentes tipos de sitios web, como blogs, tiendas de comercio electrónico, páginas web personales, etc. </Paragraph>
            <Skills/>
        </DescriptionContainer>
    );
}

const DescriptionContainer = styled.div`
    width: 45%;

    @media(max-width: 768px){
        width: 100%;
    }
`;

const Title = styled.h2`
    width: 100%;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const Paragraph = styled.p`
    font-size: 16px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 20px;
`;
 
export {Description, Title, Paragraph};