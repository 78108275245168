// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBiPk2EeK7olS_1GOU7vdpdZrceB3safc8",
  authDomain: "emeralvarado-b71c4.firebaseapp.com",
  projectId: "emeralvarado-b71c4",
  storageBucket: "emeralvarado-b71c4.appspot.com",
  messagingSenderId: "659586320603",
  appId: "1:659586320603:web:1c38ef5d2a87716ba4a4fe"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore();

const storage = getStorage(app);

const auth = getAuth();

export {db, storage, auth};