import React from 'react';
import Banner from './Banner';
import AboutMe from './AboutMe';
import Projects from './Projects';

const MainContent = () => {
    return (
        <>
            <Banner/>
            <AboutMe/>
            <Projects/>
        </>
    );
}
 
export default MainContent;