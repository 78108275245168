import React from 'react';
import styled from 'styled-components';
import eaVerde from './../imagenes/ea-verde.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';

const Banner = () => {
    return (
        <BannerContainer>
            <Presentation>
                <h1>Emerson Alvarado</h1>
                <h2>Desarrollador Web Frontend</h2>
                <p>Mi enfoque se centra en la creación de interfaces intuitivas y atractivas que mejoren la experiencia del usuario tanto en la web como en dispositivos móviles.</p>
                <Networks>
                    <NavLink to='https://wa.me/+5804126684648'>
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </NavLink>
                    <NavLink to='mailto: emeralvarado1983@gmail.com'>
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </NavLink>
                </Networks>
            </Presentation>
            <Photo>
                <img src={eaVerde}/>
            </Photo>
        </BannerContainer>
    );
}

const BannerContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 80px);

    @media(max-width: 768px){
        flex-direction: column;
        justify-content: center;
    }
`;

const Presentation = styled.div`
    width: 45%;

    h1{
        color: #fff;
        font-size: 64px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    h2{
        color: #fff;    
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    p{
        color: #fff;
        font-size: 16px;
        margin-bottom: 10px;
    }

    @media(max-width: 768px){
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
`;

const Photo = styled.div`
    width: 45%;
    text-align: right;

    img{
        width: 75%;
        border-radius: 100%;
        border: 9px solid #fff;
    }

    @media(max-width: 768px){
        text-align: center;
        margin-bottom: 20px;
    }
`;

const Networks = styled.div`
    a{
        font-size: 24px;
        margin-right: 20px;
        color: #00b080;
    }
`;
 
export default Banner;