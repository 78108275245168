import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db, storage } from '../firebase/firebaseConfig';
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth } from './../firebase/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { Warning } from './Error404';
import { Helmet } from 'react-helmet';

const Admin = () => {
    const [nombre, cambiarNombre] = useState('');
    const [enlace, cambiarEnlace] = useState('');
    const [descripcion, cambiarDescripcion] = useState('');
    const [archivos, cambiarArchivos] = useState([]);
    const [user, changeUser] = useState();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            changeUser(user);
        })
    }, []);

    const handleChange = (e) => {
        if (e.target.name === 'nombre') {
            cambiarNombre(e.target.value);
        } else if (e.target.name === 'enlace') {
            cambiarEnlace(e.target.value);
        } else if (e.target.name === 'descripcion') {
            cambiarDescripcion(e.target.value);
        } else if (e.target.name === 'archivos[]') {
            const archivosArray = Array.from(e.target.files);
            cambiarArchivos(archivosArray);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (nombre === '' || enlace === '' || descripcion === '' || archivos.length === 0) {
            alert('Faltan campos por rellenar');
        } else {
            try {
                const urls = []; // Almacena las URL de las imágenes

                // Crear una carpeta única para cada proyecto utilizando el nombre del proyecto
                const folderPath = `proyectos/${nombre}`;

                await Promise.all(archivos.map(async (archivo) => {
                    // Guardar cada archivo en la carpeta específica del proyecto
                    const storageRef = ref(storage, `${folderPath}/${archivo.name}`);
                    await uploadBytes(storageRef, archivo);
                    const url = await getDownloadURL(storageRef);
                    urls.push(url); // Agregar la URL al array de URLs
                }));

                await addDoc(collection(db, 'proyectos'), {
                    nombre: nombre,
                    enlace: enlace,
                    descripcion: descripcion,
                    imagenes: urls // Agregar las URLs al documento de Firestore
                });
            } catch (error) {
                console.log('Hubo un error al intentar guardar el proyecto');
                console.log(error);
            }

            cambiarNombre('');
            cambiarEnlace('');
            cambiarDescripcion('');
            cambiarArchivos([]); // Limpiar el estado de archivos después de enviar el formulario
        }
    }

    // Verificar si el usuario está autenticado
    if (!user) {
        return <Warning>Inicia sesión para ver este contenido</Warning>;
    }

    return (
        <>
            <Helmet>
                <title>Agrega un Proyecto</title>
            </Helmet>
            <ContenedorFormulario>
                <Title>Agrega un Proyecto</Title>
                <Formulario onSubmit={handleSubmit}>
                    <div>
                        <Input
                            type='text'
                            name='nombre'
                            placeholder='Nombre del Proyecto'
                            value={nombre}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Input
                            type='text'
                            name='enlace'
                            placeholder='Enlace del Proyecto'
                            value={enlace}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Textarea
                            name='descripcion'
                            placeholder='Descripción del Proyecto'
                            value={descripcion}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Files
                            type='file'
                            name='archivos[]'
                            multiple
                            onChange={handleChange}
                        />
                    </div>
                    <Boton type='submit'>Agregar</Boton>
                </Formulario>
            </ContenedorFormulario>
        </>
    );
}

const ContenedorFormulario = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Title = styled.h2`
    width: 100%;
    max-width: 600px;
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const Formulario = styled.form`
    width: 100%;
    max-width: 600px;
    padding: 20px;
    margin: 20px;
    background: #333138;
    border-radius: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 3px solid transparent;
    border-radius: 5px;
    outline: none;
    font-size: 16px;

    &:focus{
        border: 3px solid #00b080;
    }
`;

const Textarea = styled.textarea`
    max-width: 100%;
    min-width: 100%;
    max-height: 150px;
    min-height: 150px;
    outline: none;
    border: 3px solid transparent;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;

    &:focus{
        border: 3px solid #00b080;
    }
`;

const Files = styled.input`
    margin-bottom: 20px;
    color: #fff;
`;

const Boton = styled.button`
    background: #00b080;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
`;

export default Admin;
