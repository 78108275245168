import React from 'react';
import styled from 'styled-components';
import Header from './componentes/Header';
import MainContent from './componentes/MainContent';
import Footer from './componentes/Footer';
import Project from './componentes/Project';
import Admin from './componentes/Admin';
import Login from './componentes/Login';
import {Error404} from './componentes/Error404';
import { Route, Routes } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import favicon from './imagenes/favicon.png';

const App = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>Emerson Alvarado</title>
        <link rel="shortcut icon" href={favicon} type="image/x-icon"></link>
      </Helmet>
      <Header/>
      <Routes>
        <Route path='/' element={<MainContent/>}/>
        <Route path='/proyectos/:projectId' element={<Project/>} /> {/* Ruta genérica para proyectos */}
        <Route path='/admin' element={<Admin/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='*' element={<Error404/>}/>
      </Routes>
      <Footer/>
    </MainContainer>
  );
}

const MainContainer = styled.div`
	width: 90%;
	max-width: 1200px;
`;
 
export default App;
