import React from 'react';
import styled from 'styled-components';

const Error404 = () => {
    return (
        <Warning>
            Disculpa, la pagina que buscas no existe.
        </Warning>
    );
}

const Warning = styled.h2`
    color: #fff;
    text-align: center;
    font-size: 38px;
    padding: 30px 0;
`;
 
export {Error404, Warning};