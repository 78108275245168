import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { auth } from './../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Helmet } from 'react-helmet';

const Login = () => {
    const [correo, cambiarCorreo] = useState('');
    const [password, cambiarPassword] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        if (e.target.name === 'correo') {
            cambiarCorreo(e.target.value);
        } else if (e.target.name === 'password') {
            cambiarPassword(e.target.value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Comprobamos del lado del cliente que el correo sea valido.
        const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        if (!expresionRegular.test(correo)) {
            alert('Ingresa un correo valido');
            return;
        }

        if (correo === '' || password === '') {
            alert('Por favor rellena todos los datos');
            return;
        }

        try {
            await signInWithEmailAndPassword(auth, correo, password);
            navigate('/admin');
        } catch (error) {
            let mensaje;
            switch (error.code) {
                case 'auth/wrong-password':
                    mensaje = 'La contraseña no es correcta.'
                    break;
                case 'auth/user-not-found':
                    mensaje = 'No se encontro ninguna cuenta con este correo electrónico.'
                    break;
                default:
                    mensaje = 'Hubo un error al intentar iniciar sesion.'
                    break;
            }

        }
    }

    return (
        <>
            <Helmet>
                <title>Inicia Sesión</title>
            </Helmet>
            <ContenedorFormulario>
                <Title>Inicia Sesión</Title>
                <Formulario onSubmit={handleSubmit}>
                    <div>
                        <Input
                            type='email'
                            name='correo'
                            placeholder='Ingresa tu correo'
                            value={correo}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <Input
                            type='password'
                            name='password'
                            placeholder='Ingresa tu password'
                            value={password}
                            onChange={handleChange}
                        />
                    </div>
                    <ContenedorBoton>
                        <Boton>Iniciar Sesión</Boton>
                    </ContenedorBoton>
                </Formulario>
            </ContenedorFormulario>
        </>
    );
}

const ContenedorFormulario = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Title = styled.h2`
    width: 100%;
    max-width: 600px;
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const Formulario = styled.form`
    width: 100%;
    max-width: 600px;
    padding: 20px;
    margin: 20px;
    background: #333138;
    border-radius: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 3px solid transparent;
    border-radius: 5px;
    outline: none;
    font-size: 16px;

    &:focus{
        border: 3px solid #00b080;
    }
`;

const ContenedorBoton = styled.div`
    text-align: center;
`;

const Boton = styled.button`
    background: #00b080;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
`;

export default Login;