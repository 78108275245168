import React from 'react';
import styled from 'styled-components';
import {Description} from './Description';
import Works from './Works';

const AboutMe = () => {
    return (
        <AboutMeContainer id='about-me'>
            <Description/>
            <Works/>
        </AboutMeContainer>
    );
}

const AboutMeContainer = styled.section`
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
 
export default AboutMe;