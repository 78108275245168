import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { NavLink, useLocation } from 'react-router-dom';
import logo from './../imagenes/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [hiddenMobileNavigation, changeHiddenMobileNavigation] = useState(false);
    const [screenWidth, changeScreenWidth] = useState(window.innerWidth);
    const location = useLocation();
    
    const toggleMenu = () => {
        changeHiddenMobileNavigation(!hiddenMobileNavigation);
    }

    useEffect(() => {
        const handleResize = () => {
            changeScreenWidth(window.innerWidth);
            if(window.innerWidth > 768){
                changeHiddenMobileNavigation(false);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    // Verifica si la ruta actual contiene algo diferente a '/mosaikus' o '/bibliolatino'
    const isUrlsSecondary = location.pathname.includes('/login') || location.pathname.includes('/admin') || location.pathname.includes('/proyectos/');

    return (
        <Menu id='menu'>
            <Logo>
                <Link to='/'><img src={logo} alt='Logo personal con las iniciales de mi nombre'/></Link>
            </Logo>
            <Navigation>
                <>
                    {
                        !isUrlsSecondary ? 
                        <>
                            <Link to="menu" spy={true} smooth={true} offset={50} duration={500}>Inicio</Link>
                            <Link to="about-me" spy={true} smooth={true} offset={50} duration={500}>Sobre mí</Link>
                            <Link to="projects" spy={true} smooth={true} offset={50} duration={500}>Proyectos</Link>
                            <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>Contacto</Link>
                        </>
                        :
                        <NavLink to='/'>Volver</NavLink>
                    }
                </>
            </Navigation>
            <MobileIcon>
                <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
            </MobileIcon>
            <MobileNavigation hiddenmobilenavigation={hiddenMobileNavigation}>
                <>
                    {
                        !isUrlsSecondary ? 
                        <>
                            <Link to="menu" spy={true} smooth={true} offset={50} duration={500}>Inicio</Link>
                            <Link to="about-me" spy={true} smooth={true} offset={50} duration={500}>Sobre mí</Link>
                            <Link to="projects" spy={true} smooth={true} offset={50} duration={500}>Proyectos</Link>
                            <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>Contacto</Link>
                        </>
                        :
                        <NavLink to='/'>Volver</NavLink>
                    }
                </>
            </MobileNavigation>
        </Menu>
    );
}

const Menu = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: relative;
`;

const Logo = styled.div`
    width: 200px;

    img{
        height: 60px;
    }
`;

const Navigation = styled.nav`
    width: 1000px;
    text-align: right;

    a{
        margin-left: 20px;
        color: #fff;
        cursor: pointer;
    }

    a:hover{
        padding-bottom: 3px;
        border-bottom: 3px solid #00b080;
    }

    @media(max-width: 768px){
        display: none;
    }
`;

const MobileIcon = styled.div`
    color: #fff;
    font-size: 22px;
    display: none;

    @media(max-width: 768px){
        display: block;
    }
`;

const MobileNavigation = styled.nav`
    display: ${({hiddenmobilenavigation}) => hiddenmobilenavigation ? 'flex' : 'none' };
    flex-direction: column;
    position: absolute;
    bottom: -90vh;
    background: #232227;
    width: 100%;
    height: 90vh;
    border-top: 1px solid #00b080;

    a{
        color: #fff;
        padding: 20px 0;
        border-bottom: 1px solid #00b080;
    }
`;
 
export default Header;