import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { Title } from './Description';
import { db } from '../firebase/firebaseConfig';
import { collection, onSnapshot } from 'firebase/firestore';

const Projects = () => {

    const [projects, changeProjects] = useState([]);

    useEffect(() => {
        onSnapshot(collection(db, 'proyectos'),
            (snapshot) => {
                const ProjectsArray = snapshot.docs.map((documento) => {
                    return { ...documento.data(), id: documento.id }
                })

                changeProjects(ProjectsArray);
            }
        );
    }, []);

    return (
        <ProjectsContainer id='projects'>
            <Title>Proyectos Destacables</Title>
            {
                projects.map((project) => {
                    return (
                            <Card>
                                <CardHeader>
                                    {project.imagenes && project.imagenes.length > 0 && (
                                        <NavLink to={`/proyectos/${project.id}`} key={project.id}>
                                            <img src={project.imagenes[3]} alt={`Imagen de ${project.nombre}`} />
                                        </NavLink>
                                    )}
                                </CardHeader>
                                <CardBody>
                                    <TitleLinkContainer>
                                        <NavLink to={`/proyectos/${project.id}`} key={project.id}>
                                            <h2>{project.nombre}</h2>
                                        </NavLink>
                                        
                                        <NavLink to={project.enlace}>
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} target='_blank' />
                                        </NavLink>
                                    </TitleLinkContainer>
                                    <TechnologiesContainer>

                                    </TechnologiesContainer>
                                </CardBody>
                            </Card>
                    )
                })
            }
        </ProjectsContainer>

    );
}

const ProjectsContainer = styled.section`
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
`;

const Card = styled.article`
    background: #333138;
    margin-bottom: 30px;
    width: 25%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 3px #fff;
    margin-right: 40px;
    margin-bottom: 40px;
    position: relative;

    @media(max-width: 768px){
        width: 100%;
        margin-bottom: 60px;
    }
`;

const CardHeader = styled.div`
    border-radius: 5px;

    img{
        width: 100%;
        min-height: 350.31px;
        display: block;
        border-radius: 5px 5px 0 0;
        aspect-ratio: 960 / 1121;
    }
`;

const CardBody = styled.div`
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    h2{
        color: #fff;
        font-size: 18px;
    }
`;

const TitleLinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    a{
        color: #fff;
    }
`;

const TechnologiesContainer = styled.div`
    flex-grow: 1;

    span{
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 10px;
        border: 1px solid #fff;
        border-radius: 25px;
        color: #fff;
        background: #00b080;
        margin-right: 10px;
        margin-bottom: 10px;
    }
`;

export default Projects;