import React from 'react';
import styled from 'styled-components';
import { Title, Paragraph } from './Description';

const Works = () => {
    const works = [{
        id: 1,
        profession: 'Desarrollador Frontend - Freelancer',
        company: 'Bibliolatino',
        time: '2021 - 2024',
        text: 'He trabajado como freelancer desde 2019 con varios clientes. En particular, quiero mencionar a Bibliolatino, ya que he mantenido una relación constante con ellos a lo largo del tiempo. He atendido diversos trabajos para Bibliolatino y, hasta el día de hoy, seguimos colaborando estrechamente.'
    },
    {
        id: 2,
        profession: 'Desarrollador Web',
        company: 'Mosaikus',
        time: '2022 - 2023',
        text: 'Con la empresa Mosaikus ocupe el puesto de Desarrollador FullStack, estuve encargado de su plataforma de cursos Academia Mosaikus que hace uso del sistema de Moodle, es un CMS parecido a Wordpress. Estas fueron algunas de mis tareas: Brinde soporte y atención a errores, así como mejoras del aplicativo web Mosaikus. Personalize interfaz del home y cursos. Cree y modifiquee tablas en la base de datos. Desarrolle reportes en diferentes formatos (Excel, PDF, Word) para los módulos del aplicativo. Desarrolle actividades para la carga masiva de información en el sistema.'
    }
    ];
    return (
        <WorksContainer>
            <Title>Experiencias de Trabajo</Title>
            {
                works.map((work) => {
                    return(
                        <WorkContainer key={work.id}>
                            <h2>{work.profession}</h2>
                            <h4>{work.company}</h4>
                            <span>{work.time}</span>
                            <Paragraph>{work.text}</Paragraph>
                        </WorkContainer>
                    )
                })
            }
        </WorksContainer>
    );
}

const WorksContainer = styled.div`
    width: 45%;

    @media(max-width: 768px){
        width: 100%;
    }
`;

const WorkContainer = styled.article`
    background: #333138;
    padding: 10px 20px;
    margin-bottom: 30px;
    border-radius: 5px;

    h2{
        color: #00b080;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    h4{
        color: #00b080;
        font-weight: bold;
        margin-bottom: 4px;
    }

    span{
        color: #00b080;
        font-size: 12px;
        display: block;
        margin-bottom: 10px;
    }
`;
 
export default Works;